import React from 'react';
import swal from 'sweetalert';

const ManageBlogsRow = ({ blog, index, refetch }) => {

    const { _id, headline, author, hook, img, category } = blog;

    const handleDelete = _id => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/blog/${_id}`, {
                        method: 'DELETE',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            refetch()
                        })
                } else {
                }
            });
    }


    return (
        <tr>
            <td>
                <div className="flex items-center gap-3">
                    <div className="avatar">
                        <div className="mask mask-squircle w-20 h-20">
                            <img src={img} alt="Profile" />
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <div className="font-bold">{headline}</div>
                    <div className="text-sm opacity-50">{author}</div>
                </div>
            </td>
            <td>
                {hook}
                <br />
                <span className="badge badge-ghost badge-sm">{category}</span>
            </td>
            <th>
                <button onClick={() => handleDelete(_id)} className="btn btn-primary btn-xs">Delete</button>
            </th>
        </tr>
    );
};

export default ManageBlogsRow;