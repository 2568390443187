import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center w-full mb-20">
                    <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee</p>
                </div>
                <div class="lg:w-2/3 w-full mx-auto overflow-auto">
                    <table class="table-auto w-full text-left whitespace-no-wrap">
                        <thead>
                            <tr>
                                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Course</th>
                                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Duration</th>
                                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Price</th>
                                <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">Offer price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="px-4 py-3">IELTS</td>
                                <td class="px-4 py-3">3 months (36 Classes)</td>
                                <td class="px-4 py-3">TK. 9500</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 6500 (30% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">IELTS Booster</td>
                                <td class="px-4 py-3">2 weeks (12 Classes)</td>
                                <td class="px-4 py-3">TK. 2000</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 2000</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">IELTS Compact</td>
                                <td class="px-4 py-3">1.5 months (24 Classes)</td>
                                <td class="px-4 py-3">TK. 9500</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 6500 (20% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Grammer for IELTS</td>
                                <td class="px-4 py-3">1.5 months (18 Classes)</td>
                                <td class="px-4 py-3">TK. 4000</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 2000 (50% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Spoken</td>
                                <td class="px-4 py-3">3 months (36 Classes)</td>
                                <td class="px-4 py-3">TK. 6500</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 6500</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">Life Skills</td>
                                <td class="px-4 py-3">3 months (36 Classes)</td>
                                <td class="px-4 py-3">TK. 11500</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 7500 (40% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">English Foundation</td>
                                <td class="px-4 py-3">3 months (36 Classes)</td>
                                <td class="px-4 py-3">TK. 10000</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 6500 (30% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">HSC English + Foundation</td>
                                <td class="px-4 py-3">3 months (36 Classes)</td>
                                <td class="px-4 py-3">TK. 10000</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 6500 (30% off)</td>
                            </tr>
                            <tr>
                                <td class="px-4 py-3">HSC ICT</td>
                                <td class="px-4 py-3">2 months (24 Classes)</td>
                                <td class="px-4 py-3">TK. 3500</td>
                                <td class="px-4 py-3 text-lg text-gray-900">TK. 2000 (15% off)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex pl-4 mt-4 lg:w-2/3 w-full mx-auto">

                    <Link to='/registerStudent' class="flex ml-auto text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-secondary rounded">Register</Link>
                </div>
            </div>
        </section>
    );
};

export default Pricing;