import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Loading from "../../Shared/Loading";

const AddBlogs = () => {

    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const imageStorageKey = 'a1d7d3a7e4fde5cadc71e0a2315af238';

    const onSubmit = (data) => {
        const formData = new FormData();
        const image = data.image[0];
        formData.append('image', image);
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;
        fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(result => {
                if (result.success) {
                    setIsLoading(true)
                    const img = result.data.url;
                    const newBlog = {
                        headline: data.headline,
                        category: data.category,
                        hook: data.hook,
                        body: data.body,
                        img: img,
                        author: data.author
                    };
                    fetch(`https://parrots-academy-server-end.onrender.com/addBlogs`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: JSON.stringify(newBlog)
                    }).then(res => res.json())
                        .then(data => {
                            setIsLoading(false)
                            console.log(data);
                            reset();
                            navigate('/AdminPanel/ManageBlogs')
                        })
                }
            })
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>
            <h1 className="text-xl font-semibold text-gray-700 text-center mb-3">Add new Blog</h1>
            <div className='flex justify-center'>
                <form className="w-full max-w-2xl" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label for="category" className="text-sm text-gray-700">Category</label>
                        <select {...register("category")} name="category" className="select select-ghost w-full max-w-xs ml-2">
                            <option disabled selected>Pick one</option>
                            <option>Study</option>
                            <option>Career</option>
                            <option>Professional</option>
                        </select>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <label className="label">
                            <span className="label-text">Photo</span>
                        </label>
                        <input
                            type="file"
                            className="input w-full max-w-xs"
                            {...register("image", {
                                required: {
                                    value: true,
                                    message: 'Image is Required'
                                }
                            })}
                        />

                        <label className="label">
                            {errors.image?.type === 'required' && <span className="label-text-alt text-red-500">{errors.image?.message}</span>}
                        </label>
                    </div>

                    <div>
                        <label for="author" className="text-sm text-gray-700">Author Name</label>
                        <input
                            type="text" name="author"
                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("author", {
                                required: {
                                    value: true,
                                    message: 'Valid author name is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.author?.type === 'required' && <span className="label-text-alt text-red-500">{errors.author.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="headline" className="block mb-2 text-sm text-gray-700">Blog headline</label>
                        <input
                            type="text"
                            name="headline"
                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("headline", {
                                required: {
                                    value: true,
                                    message: 'Headline is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.headline?.type === 'required' && <span className="label-text-alt text-red-500">{errors.headline.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="hook" className="block mb-2 text-sm text-gray-700">Blog hook</label>
                        <input
                            type="text" name="hook"
                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("hook", {
                                required: {
                                    value: true,
                                    message: 'Valid hook is Required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.hook?.type === 'required' && <span className="label-text-alt text-red-500">{errors.hook.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="body" className="block mb-2 text-sm text-gray-700">Blog body</label>
                        <textarea
                            type="text" name="body"
                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("body", {
                                required: {
                                    value: true,
                                    message: 'Valid blog body is Required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.body?.type === 'required' && <span className="label-text-alt text-red-500">{errors.body.message}</span>}
                        </label>
                    </div>
                    <div className="mb-1 flex justify-center">
                        <input type="submit" className="btn btn-primary btn-sm" value="Add Blog"></input>
                    </div>
                </form>
            </div >
        </div>
    );
};

export default AddBlogs;