import React from 'react';
import Loading from '../../Shared/Loading';
import { useQuery } from 'react-query';
import ManageTeachersRow from './ManageTeachersRow';

const ManageTeachers = () => {

    const { data: teachers, isLoading, refetch } = useQuery('users', () => fetch('https://parrots-academy-server-end.onrender.com/teachers').then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }

    return (
        <div>
            <h1 className='font-semibold text-xl'>Teachers</h1>
            <div className="overflow-x-auto">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Image</th>
                            <th>Details</th>
                            <th>Position & Education</th>
                            <th>Contacts</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            teachers?.map((teacher, index) => <ManageTeachersRow
                                key={teacher._id}
                                teacher={teacher}
                                refetch={refetch}
                                index={index}
                            >
                            </ManageTeachersRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageTeachers;