import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../Shared/Loading';
import AllBlogsRow from './AllBlogsRow';

const AllBlogs = () => {

    const { data: blogs, isLoading, refetch } = useQuery('blogs', () => fetch('https://parrots-academy-server-end.onrender.com/blogs').then(res => res.json()))


    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <section className="text-gray-600 body-font mb-12 min-h-screen">
            <div className="container px-5 pt-10 mx-auto">
                <div className="text-center w-full mb-20">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">All blogs</h1>
                </div>
                <div className="flex flex-wrap m-4">
                    {
                        blogs?.map((blog) => <AllBlogsRow
                            key={blog._id}
                            blog={blog}
                            refetch={refetch}
                        >
                        </AllBlogsRow>)
                    }
                </div>
            </div>
        </section>
    );
};

export default AllBlogs;