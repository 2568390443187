import React from 'react';
import Logo from '../../../Assets/Images/Logos/parrotsLogo.png';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../Shared/Loading';
import { useQuery } from 'react-query';

const ViewStudentDetails = () => {

    const { id } = useParams();
    const { data: studentDetails, isLoading } = useQuery('studentDetails', () => fetch(`https://parrots-academy-server-end.onrender.com/viewStudentDetails/${id}`).then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }

    const name = studentDetails ? studentDetails.name : 0;
    const email = studentDetails ? studentDetails.email : 0;
    const address = studentDetails ? studentDetails.address : 0;
    const age = studentDetails ? studentDetails.age : 0;
    const course = studentDetails ? studentDetails.course : 0;
    const img = studentDetails ? studentDetails.img : 0;
    const gender = studentDetails ? studentDetails.gender : 0;
    const _id = studentDetails ? studentDetails._id : 0;
    const lastEdu = studentDetails ? studentDetails.lastEdu : 0;
    const lastSchool = studentDetails ? studentDetails.lastSchool : 0;
    const number = studentDetails ? studentDetails.number : 0;

    return (
        <div>
            <div className='container p-5'>
                <div className='flex justify-stretch items-center'>
                    <div>
                        <img src={Logo} className='w-16 h-16' alt="" />
                    </div>
                    <div className='ml-12'>
                        <h1 className='text-xl font-mono font-semibold'>Parrots Academy</h1>
                        <h1 className='text-sm font-semibold'>Learn for our future</h1>
                        <h1 className='text-xs'>info@parrotsacademy.com | +8801700000000, +8801700000000</h1>
                    </div>
                </div>
            </div>
            <div className='px-5'>
                <div className='flex justify-stretch items-center'>
                    <div>
                        <img src={img} className='avatar w-28 h-28' alt="" />
                    </div>
                    <div className='ml-12'>
                        <h1 className='text-lg font-semibold'>{name}</h1>
                    </div>
                </div>
            </div>
            <div className='px-5'>
                <div className="max-w-lg">
                    <div className="px-8 pt-2 mb-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="age">
                                Age
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="age" type="text" value={age} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                                Gender
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="gender" type="text" value={gender} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                                Address
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" type="text" value={address} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" value={email} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
                                Number
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="text" value={number} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="course">
                                Course
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="course" type="text" value={course} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastEdu">
                                Last Educational Qualification
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lastEdu" type="text" value={lastEdu} disabled />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastSchool">
                                Last Educational Institution
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lastSchool" type="text" value={lastSchool} disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <footer class="text-gray-600 body-font">
                    <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
                        <Link to='/' class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                            <span class="ml-3 text-xl">Parrots Academy</span>
                        </Link>
                        <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                            <a class="text-gray-500">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" class="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </a>
                            <a class="ml-3 text-gray-500">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" class="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                </svg>
                            </a>
                            <a class="ml-3 text-gray-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" class="w-5 h-5" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </a>
                            <a class="ml-3 text-gray-500">
                                <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" class="w-5 h-5" viewBox="0 0 24 24">
                                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                </svg>
                            </a>
                        </span>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default ViewStudentDetails;