import React from 'react';
import parrots_news1 from '../../../Assets/Images/News/parrots_news1.jpg';
import parrots_news2 from '../../../Assets/Images/News/parrots_news2.jpg';
import parrots_news3 from '../../../Assets/Images/News/parrots_news3.jpg';

const Offers = () => {
    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto flex flex-wrap">
                <div class="flex flex-col text-center w-full mb-20">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Exclusive News</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in diam eu tellus lobortis congue id vestibulum nunc.</p>
                </div>
                <div class="lg:w-2/3 mx-auto">
                    <div class="flex flex-wrap w-full bg-gray-100 py-32 px-10 relative mb-4">
                        <img alt="gallery" class="w-full object-cover h-full object-center block opacity-70 absolute inset-0" src={parrots_news1} />
                        <div class="text-center relative z-10 w-full">
                            <h2 class="text-2xl text-black font-medium title-font mb-2">Taposhi Rabeya getting Band 7.0 in IELTS Academics</h2>
                            <p class="text-black leading-relaxed">Student from our firth batch</p>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-2">
                        <div class="px-2 w-1/2">
                            <div class="flex flex-wrap w-full bg-gray-100 sm:py-24 py-16 sm:px-10 px-6 relative">
                                <img alt="gallery" class="w-full object-cover h-full object-center block opacity-70 absolute inset-0" src={parrots_news2} />
                                <div class="text-center relative z-10 w-full">
                                    <h2 class="text-xl text-gray-900 font-medium title-font mb-2">Award giving ceremony</h2>
                                    <p class="leading-relaxed">Scholarship award giving ceremony</p>
                                </div>
                            </div>
                        </div>
                        <div class="px-2 w-1/2">
                            <div class="flex flex-wrap w-full bg-gray-100 sm:py-24 py-16 sm:px-10 px-6 relative">
                                <img alt="gallery" class="w-full object-cover h-full object-center block opacity-70 absolute inset-0" src={parrots_news3} />
                                <div class="text-center relative z-10 w-full">
                                    <h2 class="text-xl text-gray-900 font-medium title-font mb-2">Scholarship awarded to students</h2>
                                    <p class="leading-relaxed">Students got Full waived or 70% discount</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Offers;