import React from 'react';
import Hero from './Hero';
import Offers from './Offers/Offers';
import Categories from './Categories/Categories';
import Pricing from './Pricing/Pricing';
import Testinomial from './Testinomial';
import Team from './Team/Team';
import ContactUs from './ContactUs';
import Blogs from './Blogs/Blogs';

const Home = () => {
    return (
        <div className='min-h-screen'>
            <Hero />
            <Offers />
            <Blogs />
            <Categories />
            <Pricing />
            <Testinomial />
            <Team />
            <ContactUs />
        </div>
    );
};

export default Home;