import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import About from "./Pages/About/About";
import Home from "./Pages/Home/Home";
import Footer from "./Pages/Shared/Footer";
import Navbar from "./Pages/Shared/Navbar";
import Contact from "./Pages/Contact/Contact";
import Login from "./Pages/Authentication/Login";
import StudentProfile from "./Pages/Authentication/StudentProfile";
import Register from "./Pages/Authentication/Register";
import Appointment from "./Pages/Appointment/Appointment";
import RequiredAuth from "./Pages/Authentication/RequireAuth";
import StudentPortal from "./Pages/StudentPortal/StudentPortal";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import AllUsers from "./Pages/AdminPanel/AllUsers";
import AddBlogs from "./Pages/AdminPanel/Blogs/AddBlogs";
import ManageBlogs from "./Pages/AdminPanel/Blogs/ManageBlogs";
import AddStudents from "./Pages/AdminPanel/Students/AddStudents";
import ManageStudents from "./Pages/AdminPanel/Students/ManageStudents";
import ManageTeachers from "./Pages/AdminPanel/Teachers/ManageTeachers";
import AddTeachers from "./Pages/AdminPanel/Teachers/AddTeachers";
import ManageAppointments from "./Pages/AdminPanel/Appointments/ManageAppointments";
import ViewStudentDetails from "./Pages/AdminPanel/Students/ViewStudentDetails";
import ViewBlogs from "./Pages/Home/Blogs/ViewBlogs";
import AllBlogs from "./Pages/Home/Blogs/AllBlogs";
import useAdmin from "./hooks/useAdmin";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "./firebase.init";
import NotFound from "./Pages/Shared/NotFound";

function App() {

  const location = useLocation();

  const excludeNavbarFooter = location.pathname.startsWith("/viewStudentDetails/");

  const user = useAuthState(auth);
  const [admin] = useAdmin(user);

  return (
    <div>
      {!excludeNavbarFooter && <Navbar />}

      {admin ? (

        <>
          <Routes>
            <Route path="/viewStudentDetails/:id" element={<RequiredAuth><ViewStudentDetails /></RequiredAuth>}></Route>
            <Route path='/AdminPanel' element={<RequiredAuth><AdminPanel /></RequiredAuth>}>
              <Route index element={<ManageAppointments />} />
              <Route path='/AdminPanel/AddBlogs' element={<AddBlogs />} />
              <Route path='/AdminPanel/ManageBlogs' element={<ManageBlogs />} />
              <Route path='/AdminPanel/AddStudents' element={<AddStudents />} />
              <Route path='/AdminPanel/ManageStudents' element={<ManageStudents />} />
              <Route path='/AdminPanel/AddTeachers' element={<AddTeachers />} />
              <Route path='/AdminPanel/ManageTeachers' element={<ManageTeachers />} />
              <Route path='/AdminPanel/AllUsers' element={<AllUsers />} />
            </Route>
          </Routes>
        </>

      ) : (
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path='*' element={<NotFound />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/loginStudent" element={<Login />}></Route>
          <Route path="/registerStudent" element={<Register />}></Route>
          <Route path="/studentProfile" element={<RequiredAuth><StudentProfile /></RequiredAuth>}></Route>
          <Route path="/bookApppointment" element={<RequiredAuth><Appointment /></RequiredAuth>}></Route>
          <Route path="/studentPortal" element={<RequiredAuth><StudentPortal /></RequiredAuth>}></Route>
          <Route path="/allBlogs" element={<RequiredAuth><AllBlogs /></RequiredAuth>}></Route>
          <Route path="/viewBlogs/:id" element={<RequiredAuth><ViewBlogs /></RequiredAuth>}></Route>
        </Routes>
      )}


      {!excludeNavbarFooter && <Footer />}
    </div>
  );
}

export default App;