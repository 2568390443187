import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const StudentPortalRow = ({ studentProfile, refetch }) => {

    const { name, img, address, email, course, age, number } = studentProfile;
    return (
        <div>
            <Tabs>
                <TabList>
                    <Tab>{name}</Tab>
                    <Tab >Course Materials</Tab>
                    <Tab >Routine</Tab>
                </TabList>

                <TabPanel>
                    <section class="text-gray-600 body-font overflow-hidden">
                        <div class="container px-5 py-10 mx-auto">
                            <div class="lg:w-4/5 mx-auto flex flex-wrap">
                                <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src={img} />
                                <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                                    <h2 class="text-sm title-font text-gray-500 tracking-widest">Student Details</h2>
                                    <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{name}</h1>
                                    <span class="text-gray-900 text-lg mb-1">{email},</span>
                                    <span class="text-gray-900 text-lg mb-1"> {number}</span>
                                    <h1 class="text-gray-900 text-lg mb-1">{address}</h1>

                                    <p class="leading-relaxed">Age: {age}</p>
                                    <div class="flex mt-4">
                                        <span class="title-font font-medium text-2xl text-gray-900">{course}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </TabPanel>
                <TabPanel>
                    <p>
                        <b>Latest Materials</b><br />
                    </p>
                    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                        <div class="p-2 sm:w-1/2 w-full">
                            <a href='www.google.com' class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Cambridge IELTS 18 Prep Book</span>
                            </a>
                        </div>
                        <div class="p-2 sm:w-1/2 w-full">
                            <div class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Parrots Academy Guide for IELTS Speaking</span>
                            </div>
                        </div>
                        <div class="p-2 sm:w-1/2 w-full">
                            <div class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Cambridge IELTS 17 Prep Book</span>
                            </div>
                        </div>
                        <div class="p-2 sm:w-1/2 w-full">
                            <div class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Cambridge IELTS 16 Prep Book</span>
                            </div>
                        </div>
                        <div class="p-2 sm:w-1/2 w-full">
                            <div class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Cambridge IELTS 15 Prep Book</span>
                            </div>
                        </div>
                        <div class="p-2 sm:w-1/2 w-full">
                            <div class="bg-gray-200 rounded-xl flex p-4 h-full items-center">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-primary w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                    <path d="M22 4L12 14.01l-3-3"></path>
                                </svg>
                                <span class="title-font font-medium">Cambridge IELTS 14 Prep Book</span>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <p>
                        <b>Class Routine</b>
                    </p>
                    <section class="text-gray-600 body-font">
                        <div class="container px-5 py-10 mx-auto">
                            <div class="flex flex-wrap -mx-4 -my-8">
                                <div class="py-8 px-4 lg:w-1/3">
                                    <div class="h-full flex items-start">
                                        <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                                            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">Mar</span>
                                            <span class="font-medium text-lg text-gray-800 title-font leading-none">26</span>
                                        </div>
                                        <div class="flex-grow pl-6">
                                            <h1 class="title-font text-xl font-medium text-gray-900 mb-3">Start Date</h1>
                                            <p class="leading-relaxed mb-5">Instructions</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-8 px-4 lg:w-1/3">
                                    <div class="h-full flex items-start">
                                        <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                                            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">Jul</span>
                                            <span class="font-medium text-lg text-gray-800 title-font leading-none">30</span>
                                        </div>
                                        <div class="flex-grow pl-6">
                                            <h1 class="title-font text-xl font-medium text-gray-900 mb-3">End Date</h1>
                                            <p class="leading-relaxed mb-5">Instructions</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-8 px-4 lg:w-1/3">
                                    <div class="h-full flex items-start">
                                        <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                                            <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">May</span>
                                            <span class="font-medium text-lg text-gray-800 title-font leading-none">18</span>
                                        </div>
                                        <div class="flex-grow pl-6">
                                            <h1 class="title-font text-xl font-medium text-gray-900 mb-3">Exam</h1>
                                            <p class="leading-relaxed mb-5">Instructions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default StudentPortalRow;