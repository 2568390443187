import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../Shared/Loading';
import ManageAppointmentsRow from './ManageAppointmentsRow';

const ManageAppointments = () => {

    const { data: appointments, isLoading, refetch } = useQuery('users', () => fetch('https://parrots-academy-server-end.onrender.com/appointments').then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }

    return (
        <div>
            <h1 className='font-semibold text-xl'>Appointments</h1>
            <div className="overflow-x-auto">
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr>
                            <th></th>
                            <th>Details</th>
                            <th>Schedule and Contacts</th>
                            <th>Description</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            appointments?.map((appointment, index) => <ManageAppointmentsRow
                                key={appointment._id}
                                appointment={appointment}
                                refetch={refetch}
                                index={index}
                            >
                            </ManageAppointmentsRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageAppointments;