import React from 'react';
import swal from 'sweetalert';

const ManageAppointmentsRow = ({ appointment, index, refetch }) => {

    const { _id, name, number, purpose, email, time, date, description } = appointment;


    const handleDelete = _id => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/appointments/${_id}`, {
                        method: 'DELETE',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            refetch()
                        })
                } else {
                }
            });
    }


    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <div className="flex items-center gap-3">
                    <div>
                        <div className="font-bold">{name}</div>
                        <div className="text-sm opacity-50">Reason: <span className='font-bold'>{purpose}</span></div>
                    </div>
                </div>
            </td>
            <td>
                Time: <span className='font-bold'>{time}</span>, Date: <span className='font-bold'>{date}</span>
                <br />
                <span className="badge badge-ghost badge-sm font-bold">{email}, {number}</span>
            </td>
            <td>{description}</td>
            <th>
                <button onClick={() => handleDelete(_id)} className="btn btn-primary btn-xs">Delete</button>
            </th>
        </tr>
    );
};

export default ManageAppointmentsRow;