import React from 'react';
import notFound from '../../Assets/Images/notFound.jpg';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section class="text-gray-600 body-font">
            <div class="container mx-auto flex flex-col px-5 py-6 justify-center items-center">
                <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-6 object-cover object-center rounded" alt="hero" src={notFound} />
                <div class="w-full md:w-2/3 flex flex-col mb-6 items-center text-center">
                    <h1 class="title-font sm:text-4xl text-3xl mb-2 font-medium text-gray-900">Page not found</h1>
                    <p class="mb-2 leading-relaxed">Go back home and try again</p>
                    <Link to='/' class="mb-8 btn btn-sm btn-primary">Home</Link>
                </div>
            </div>
        </section>
    );
};

export default NotFound;