import React from 'react';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const AllUsersRow = ({ user, index, refetch }) => {

    const { _id, name, address, email, number, role, img, age, gender } = user;

    const handleDelete = _id => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/user/${_id}`, {
                        method: 'DELETE',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            refetch()
                        })
                } else {
                }
            });
    }

    const makeAdmin = email => {

        swal({
            title: "Are you sure?",
            text: "This user will have Admin power!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/allUsers/admin/${email}`, {
                        method: 'PUT',
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => {
                            if (res.status === 403) {
                                Swal.fire('Only an admin make make another admin');
                            }
                            return res.json()
                        })
                        .then(data => {
                            if (data.modifiedCount > 0) {
                                refetch();
                                Swal.fire(`Successfully made an admin`);
                            }

                        })
                } else {
                }
            });
    }


    const removeAdmin = email => {

        swal({
            title: "Are you sure?",
            text: "This user will not have Admin power!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/allUser/admin/${email}`, {
                        method: 'PUT',
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => {
                            if (res.status === 403) {
                                Swal.fire('Only an admin make make remove admin');
                            }
                            return res.json()
                        })
                        .then(data => {
                            if (data.modifiedCount > 0) {
                                refetch();
                                Swal.fire(`Successfully removed an admin`);
                            }

                        })
                } else {
                }
            });
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <div className="flex items-center gap-3">
                    <div className="avatar">
                        <div className="mask mask-squircle w-20 h-20">
                            <img src={img} alt="Profile" />
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <div className="font-bold">{name}</div>
                    <div className="text-xs opacity-50">{age}, {gender}</div>
                </div>
            </td>
            <td>
                {address}
                <br />
                <span className="badge badge-ghost badge-sm">{email}, {number}</span>
            </td>
            <td>
                {role === 'admin' ? <button onClick={() => removeAdmin(email)} className="btn btn-xs btn-success">Remove Admin</button> : <button onClick={() => makeAdmin(email)} className="btn btn-warning btn-xs">Make Admin</button>}
            </td>
            <th>
                <button onClick={() => handleDelete(_id)} className="btn btn-primary btn-xs">Delete</button>
            </th>
        </tr>
    );
};

export default AllUsersRow;