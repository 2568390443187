import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import Loading from "../../Shared/Loading";

const AddStudents = () => {

    const user = useAuthState(auth);
    const email = user[0]?.email;

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const imageStorageKey = 'a1d7d3a7e4fde5cadc71e0a2315af238';

    const onSubmit = (data) => {
        const formData = new FormData();
        const image = data.image[0];
        formData.append('image', image);
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;
        fetch(url, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
            .then(result => {
                if (result.success) {
                    setIsLoading(true)
                    const img = result.data.url;
                    const newStudent = {
                        name: data.name,
                        email: data.email,
                        address: data.address,
                        age: data.age,
                        course: data.course,
                        img: img,
                        gender: data.gender,
                        lastEdu: data.lastEdu,
                        lastSchool: data.lastSchool,
                        number: data.number,
                    };
                    fetch(`https://parrots-academy-server-end.onrender.com/addStudent`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: JSON.stringify(newStudent)
                    }).then(res => res.json())
                        .then(data => {
                            setIsLoading(false)
                            console.log(data);
                            reset();
                            navigate('/AdminPanel/ManageStudents');
                        })
                }
            })
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>
            <h1 className="text-xl font-semibold text-gray-700 text-center mb-3">Add new student</h1>
            <div className='flex justify-center'>
                <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label for="name" className="text-sm text-gray-700">Student Name <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="name"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("name", {
                                required: {
                                    value: true,
                                    message: 'Valid student name is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.name?.type === 'required' && <span className="label-text-alt text-red-500">{errors.name.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="email" className="block text-sm text-gray-700">Email Address <span className='text-red-500'>*</span></label>
                        <input
                            type="email" name="email" placeholder="you@email.com"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: 'Valid email is required'
                                },
                                pattern: {
                                    value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                    message: 'Provide a valid Email'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.email?.type === 'required' && <span className="label-text-alt text-red-500">{errors.email.message}</span>}
                            {errors.email?.type === 'pattern' && <span className="label-text-alt text-red-500">{errors.email.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="number" className="block text-sm text-gray-700">Contact Number <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="number" placeholder="+8801xxxxxxxxx"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("number", {
                                required: {
                                    value: true,
                                    message: 'Valid contact number is required'
                                },
                                pattern: {
                                    value: /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/,
                                    message: 'Provide a valid bangladeshi contact number with country code (+88)'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.number?.type === 'required' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                            {errors.number?.type === 'pattern' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="age" className="text-sm text-gray-700">Age <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="age"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("age", {
                                required: {
                                    value: true,
                                    message: 'Valid age is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.age?.type === 'required' && <span className="label-text-alt text-red-500">{errors.age.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="gender" className="text-sm text-gray-700">Gender <span className='text-red-500'>*</span></label>
                        <select {...register("gender")} name="gender" className="select w-full max-w-xs ml-2">
                            <option disabled selected>Pick a gender</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Others</option>
                        </select>
                    </div>
                    <div>
                        <label for="address" className="block text-sm text-gray-700">Address <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="address"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("address", {
                                required: {
                                    value: true,
                                    message: 'Valid address is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.address?.type === 'required' && <span className="label-text-alt text-red-500">{errors.address.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="lastEdu" className="block text-sm text-gray-700">Last Education Qualification <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="lastEdu"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("lastEdu", {
                                required: {
                                    value: true,
                                    message: 'Valid last education qualification is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.lastEdu?.type === 'required' && <span className="label-text-alt text-red-500">{errors.lastEdu.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="lastSchool" className="block text-sm text-gray-700">Last Education Institution <span className='text-red-500'>*</span></label>
                        <input
                            type="text" name="lastSchool"
                            className="w-full px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("lastSchool", {
                                required: {
                                    value: true,
                                    message: 'Valid last education institution is required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.lastSchool?.type === 'required' && <span className="label-text-alt text-red-500">{errors.lastSchool.message}</span>}
                        </label>
                    </div>
                    <div>
                        <label for="course" className="text-sm text-gray-700">Course <span className='text-red-500'>*</span></label>
                        <select {...register("course")} name="course" className="select w-full max-w-xs ml-2">
                            <option disabled selected>Pick a course</option>
                            <option>IELTS</option>
                            <option>Spoken</option>
                            <option>Arabic</option>
                        </select>
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <label className="label">
                            <span className="label-text">Photo <span className='text-red-500'>*</span></span>
                        </label>
                        <input
                            type="file"
                            className="input w-full max-w-xs"
                            {...register("image", {
                                required: {
                                    value: true,
                                    message: 'Valid image is required'
                                }
                            })}
                        />

                        <label className="label">
                            {errors.image?.type === 'required' && <span className="label-text-alt text-red-500">{errors.image?.message}</span>}
                        </label>
                    </div>
                    <div className="mb-1 flex justify-center">
                        <input type="submit" className="btn btn-primary btn-sm" value="Add Student"></input>
                    </div>
                </form>
            </div >
        </div>
    );
};

export default AddStudents;