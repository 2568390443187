import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Shared/Loading';

const ViewBlogs = () => {

    const navigate = useNavigate()
    const { id } = useParams();

    const { data: blog, isLoading } = useQuery('blog', () => fetch(`https://parrots-academy-server-end.onrender.com/blog/${id}`).then(res => res.json()))


    if (isLoading) {
        return <Loading></Loading>
    }


    return (
        <div>
            <section>

                <div class="mx-auto max-w-screen-xl px-4 py-10 sm:px-6 sm:py-10 lg:px-8">
                    <button className='btn btn-xs btn-ghost' onClick={() => navigate(-1)}>◀︎ Go Back</button>
                    <div class="max-w-3xl">
                        <h2 class="text-3xl font-bold sm:text-4xl">
                            {blog?.headline}
                        </h2>
                    </div>
                    <address class="flex items-center mb-6 not-italic mt-5">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900">
                            <div>
                                <p href="#" rel="author" class="text-xl font-bold text-gray-900">{blog?.author}</p>
                                <p class="text-base font-light text-gray-700">{blog?.category}</p>
                            </div>
                        </div>
                    </address>
                    <div class="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div class="relative h-96 overflow-hidden sm:h-80">
                            <img
                                alt="Party"
                                src={blog?.img}
                                class="absolute inset-0 w-full"
                            />
                        </div>

                        <div class="lg:py-16">
                            <article class="space-y-4 text-gray-600 text-xl font-semibold">
                                <p align='justify'>
                                    {blog?.hook}
                                </p>
                            </article>
                        </div>
                    </div>
                    <div>
                        <div class="lg:py-16">
                            <article class="space-y-4 text-gray-800 text-lg">
                                {blog?.body}
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ViewBlogs;