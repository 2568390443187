import React from 'react';
import { Link } from 'react-router-dom';

const TeamRow = ({ team, refetch }) => {

    const { name, img, lastEdu, email, lastSchool, position, number } = team;

    return (
        <Link to='/' class="p-4 lg:w-1/4 md:w-1/2 border bg-gray-100 rounded-xl">
            <div class="h-full flex flex-col items-center text-center">
                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4" src={img} />
                <div class="w-full">
                    <h2 class="title-font font-medium text-lg text-gray-900">{name}</h2>
                    <h3 class="text-gray-500">{position}</h3>
                    <h3 class="text-gray-500 mb-3">{lastEdu}, {lastSchool}</h3>
                    <p class="mb-4">DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>
                </div>
            </div>
        </Link>
    );
};

export default TeamRow;