import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../../Shared/Loading';
import { Link } from 'react-router-dom';
import TeamRow from './TeamRow';

const Team = () => {

    const { data: teams, isLoading, refetch } = useQuery('teams', () => fetch(`https://parrots-academy-server-end.onrender.com/teachers`).then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }

    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center w-full mb-20">
                    <h1 class="text-2xl font-medium title-font mb-4 text-gray-900">Our Teachers</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them.</p>
                </div>
                <div class="flex flex-wrap -m-4 px-4">
                    {
                        teams?.map((team) => <TeamRow
                            key={team._id}
                            team={team}
                            refetch={refetch}
                        >
                        </TeamRow>)
                    }
                </div>
            </div>
        </section>
    );
};

export default Team;