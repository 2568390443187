import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const ManageStudentsRow = ({ student, index, refetch }) => {

    const { _id, name, address, email, number, course, img, age, gender, lastEdu, lastSchool } = student;

    const handleDelete = _id => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(`https://parrots-academy-server-end.onrender.com/student/${_id}`, {
                        method: 'DELETE',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            refetch()
                        })
                } else {
                }
            });
    }

    const navigate = useNavigate();

    const viewDetails = (id) => {
        navigate(`/viewStudentDetails/${id}`);
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <div className="flex items-center gap-3">
                    <div className="avatar">
                        <div className="mask mask-squircle w-20 h-20">
                            <img src={img} alt="Profile" />
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <div className="font-bold">{name}</div>
                    <div className="text-xs opacity-50">{age}, {gender}</div>
                </div>
            </td>
            <td>
                {course}
                <br />
                <span className="badge badge-ghost badge-sm">{lastEdu}, {lastSchool}</span>
            </td>
            <td>
                {address}
                <br />
                <span className="badge badge-ghost badge-sm">{email}, {number}</span>
            </td>
            <th>
                <button onClick={() => viewDetails(_id)} className="btn btn-success btn-xs mr-2">View</button>
                <button onClick={() => handleDelete(_id)} className="btn btn-primary btn-xs">Delete</button>
            </th>
        </tr >
    );
};

export default ManageStudentsRow;