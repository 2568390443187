import React from 'react';
import Loading from '../../Shared/Loading';
import { useQuery } from 'react-query';
import ManageBlogsRow from './ManageBlogsRow';

const ManageBlogs = () => {

    const { data: blogs, isLoading, refetch } = useQuery('users', () => fetch('https://parrots-academy-server-end.onrender.com/blogs').then(res => res.json()))

    if (isLoading) {
        <Loading></Loading>
    }

    return (
        <div>
            <h1 className='font-semibold text-xl'>Blogs</h1>
            <div className="overflow-x-auto">
                <table className="table">
                    {/* head */}
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Headline and Author</th>
                            <th>Hook and Category</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            blogs?.map((blog, index) => <ManageBlogsRow
                                key={blog._id}
                                blog={blog}
                                refetch={refetch}
                                index={index}
                            >
                            </ManageBlogsRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageBlogs;