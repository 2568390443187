import React from 'react';

const Hero = () => {
    return (
        <div className="hero min-h-screen" style={{ backgroundImage: 'url(https://daisyui.com/images/stock/photo-1507358522600-9f71e620c44e.jpg)' }}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className="hero-content text-center text-neutral-content">
                <div className="max-w-xl">
                    <h1 className="mb-5 text-6xl font-bold">Learn for our future</h1>
                    <p className="mb-5 text-2xl">A standalone institution for all your Study Aid and Standardized test preparation needs
                    </p>
                    <button className="btn btn-primary">Apply now</button>
                </div>
            </div>
        </div>
    );
};

export default Hero;