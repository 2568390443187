import React from 'react';
import hero2 from '../../Assets/Images/hero2.jpg';
import { useQuery } from 'react-query';
import Loading from '../Shared/Loading';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';

import 'react-tabs/style/react-tabs.css';
import StudentPortalRow from './StudentPortalRow';

const StudentPortal = () => {

    const user = useAuthState(auth);
    const email = user[0].email;

    // const [studentProfile, setStudentProfile] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    const { data: studentProfiles, isLoading, refetch } = useQuery('allMerchs', () => fetch(`https://parrots-academy-server-end.onrender.com/studentProfile/${email}`).then(res => res.json()))
    // useEffect(() => {
    //     fetch(`https://parrots-academy-server-end.onrender.com/studentProfile/${email}`, {
    //         method: 'GET',
    //         headers: {
    //             'content-type': 'application/json',
    //         }
    //     })
    //         .then(res => res.json())
    //         .then(data => {

    //             setStudentProfile(data[0]?.upazillas)
    //             setIsLoading(false)
    //         })
    // }, [email])

    if (isLoading === true) {
        <Loading></Loading>
    }

    console.log(studentProfiles);

    return (
        <div className='min-h-screen'>
            <div className="hero">
                <div className="hero-content">
                    <div>
                        <h1 className="text-3xl font-semibold">Welcome to student portal !</h1>
                        <span className='flex items-center'>
                            <p className='py-2 pr-3 text-xl text-primary'>Visit our 10th Feb event</p>
                            <button className="mt-1 btn btn-primary btn-xs">Click for details</button>
                        </span>
                    </div>
                </div>
            </div>
            <div className='w-4/6 mx-auto'>
                {
                    studentProfiles?.map((studentProfile) => <StudentPortalRow
                        key={studentProfile._id}
                        studentProfile={studentProfile}
                        refetch={refetch}
                    >
                    </StudentPortalRow>)
                }
            </div>
        </div>
    );
};

export default StudentPortal;